import Base from './Base';

export default class BookingSource extends Base {
  id: number;
  active: boolean;
  name: string;
  otaSource: string;

  constructor(data: any) {
    super();
    this.id = data.id;
    this.active = data.active;
    this.name = data.name;
    this.otaSource = data.otaSource;
  }

  static parseFromData(data: any): BookingSource {
    return new BookingSource({
      id: data.id,
      active: data.active === 1,
      name: data.name,
      otaSource: data.ota_source,
    });
  }
}
