import { Account } from './Account';

export class Cleaner {
  active: boolean;
  dateOnline: string;
  email: string;
  id: number;
  mobile: string;
  name: string;
  online: boolean;
  teamId: number;
  isManager: boolean;
  jobType: number;
  account?: Account;
  canSeeAreaNotes: boolean;
  showBookingSources: boolean;
  canContactGuests: boolean;
  cleaningDaysAdvance: number;
  deletedAt: string;

  constructor(data) {
    this.active = data.active;
    this.dateOnline = data.dateOnline;
    this.email = data.email;
    this.id = data.id;
    this.mobile = data.mobile;
    this.name = data.name;
    this.online = data.online;
    this.teamId = data.teamId;
    this.isManager = data.isManager;
    this.jobType = data.jobType;
    this.account = data.account || null;
    this.canSeeAreaNotes = data.canSeeAreaNotes || false;
    this.showBookingSources = data.showBookingSources || false;
    this.canContactGuests = data.canContactGuests || false;
    this.cleaningDaysAdvance = data.cleaningDaysAdvance || 31;
    this.deletedAt = data.deletedAt || null;
  }

  static parseFromData(data: any): Cleaner {
    return new Cleaner({
      active: data.active,
      dateOnline: data.date_online,
      email: data.email,
      id: data.id,
      mobile: data.mobile,
      name: data.name,
      online: data.online,
      teamId: data.team_id,
      account: data.account,
      isManager: parseInt(data.is_manager, 10) === 1,
      jobType: data.job_type,
      canSeeAreaNotes: data.canSeeAreaNotes,
      showBookingSources: data.showBookingSources,
      canContactGuests: data.canContactGuests,
      cleaningDaysAdvance: data.cleaningDaysAdvance,
      deletedAt: data.deleted_at,
    });
  }

  manager() {
    return this.isManager === true && this.teamId > 0;
  }
}
