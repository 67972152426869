import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { Observable } from 'rxjs';
import Area from '../models/Area';
import { AreaStore } from '../services/area/area.store';
import { BaseResolver } from '../services/base.resolver';
import { catchError } from 'rxjs/operators';

@Injectable()
export class AreaResolver extends BaseResolver implements Resolve<Area[]> {
  constructor(
    protected areaStore: AreaStore,
    protected router: Router,
    protected toastController: ToastController
  ) {
    super(router, toastController);
  }

  resolve(): Observable<Area[]> {
    return this.areaStore
      .loadData()
      .pipe(catchError((e: string) => this.handleResolverError(e)));
  }
}
