import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { finalize, tap, catchError } from 'rxjs/operators';
import { BaseStore } from '../base.store';
import { Cleaner } from 'src/app/models/Cleaner';
import { CleanerService } from '../cleaner/cleaner.service';

@Injectable({ providedIn: 'root' })
export class CleanerStore extends BaseStore {
  private readonly _cleaners = new BehaviorSubject<Cleaner[]>([]);

  // eslint-disable-next-line @typescript-eslint/member-ordering
  readonly cleaners$ = this._cleaners.asObservable();

  constructor(protected cleanerService: CleanerService) {
    super();
  }
  get cleaners(): Cleaner[] {
    return this._cleaners.getValue();
  }

  set cleaners(val: Cleaner[]) {
    this._cleaners.next(val);
  }

  getCleanerById(cleanerId: number): Cleaner {
    return (
      this.cleaners.find((cleaner: Cleaner) => cleaner.id === cleanerId) || null
    );
  }

  loadDataIfEmpty(): Observable<Cleaner[]> {
    if (this.cleaners.length === 0) {
      return this.loadData().pipe(catchError(() => []));
    }
    return of([]);
  }

  loadData() {
    this.loading = true;

    this.error = null;
    return this.cleanerService.fetchAll().pipe(
      finalize(() => (this.loading = false)),
      tap((cleaners: Cleaner[]) => {
        this.cleaners = cleaners;
      })
    );
  }

  cleanersFromMyTeam(teamId: number, activeOnly: boolean): Cleaner[] {
    return this._cleaners
      .getValue()
      .filter(
        (cleaner: Cleaner) =>
          cleaner.teamId === teamId &&
          (!activeOnly || cleaner.deletedAt === null)
      );
  }
}
