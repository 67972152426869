import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UserStore } from './user.store';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  constructor(protected router: Router, protected userStore: UserStore) {
    this.router = router;
    this.userStore = userStore;
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let modified = req;
    if (!req.headers.has('Skip-Auth')) {
      const reference = this.userStore.user
        ? this.userStore.user.account.reference
        : null;
      modified = req.clone({
        setHeaders: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'Content-Type': 'application/json',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'X-Account-Reference': reference,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Authorization: this.userStore.user
            ? this.userStore.user.account.token
            : null,
        },
      });
      return next.handle(modified).pipe(
        catchError((error: any) => {
          if (error.status === 401) {
            this.userStore.logout().finally(() => {
              this.router.navigate(['auth/login'], {
                queryParams: {
                  returnUrl: this.router.routerState.snapshot.url,
                },
              });
            });
            return EMPTY;
          } else {
            return throwError(() => error);
          }
        })
      );
    } else {
      const headers = req.headers.delete('Skip-Auth');
      modified = req.clone({ headers });
      return next.handle(modified);
    }
  }
}
