import { BehaviorSubject } from 'rxjs';

export class BaseStore {
  constructor() {}

  // eslint-disable-next-line @typescript-eslint/member-ordering
  protected readonly _loading = new BehaviorSubject<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  protected readonly _error = new BehaviorSubject<string | null>(null);

  // eslint-disable-next-line @typescript-eslint/member-ordering
  readonly loading$ = this._loading.asObservable();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  readonly error$ = this._error.asObservable();

  get error(): any {
    return this._error.getValue();
  }
  get loading(): any {
    return this._loading.getValue();
  }
  set loading(val: any) {
    this._loading.next(val);
  }
  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  set error(val: any) {
    this._error.next(val);
  }
}
