import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { finalize, tap, catchError } from 'rxjs/operators';
import { DeviceService } from './device.service';
import { BaseStore } from '../base.store';
import { Device } from 'src/app/models/Device';

@Injectable({ providedIn: 'root' })
export class DeviceStore extends BaseStore {
  private readonly _devices = new BehaviorSubject<Device[]>([]);

  // eslint-disable-next-line @typescript-eslint/member-ordering
  readonly devices$ = this._devices.asObservable();

  constructor(protected deviceService: DeviceService) {
    super();
  }

  get devices(): Device[] {
    return this._devices.getValue();
  }

  set devices(val: Device[]) {
    this._devices.next(val);
  }
  loadDataIfEmpty(): Observable<Device[]> {
    if (this.devices.length === 0) {
      return this.loadData().pipe(catchError(() => []));
    }
    return of([]);
  }

  loadData(): Observable<Device[]> {
    this.error = null;
    return this.deviceService.getAll().pipe(
      finalize(() => (this.loading = false)),
      tap((devices: Device[]) => {
        this.devices = devices;
      })
    );
  }

  manageSubscription(deviceId: string, subscribed: boolean) {
    const existingDevice = this.devices.find(
      (device: Device) => device.deviceId === deviceId
    );
    if (existingDevice) {
      return this.deviceService.modifySubscription({
        deviceId,
        subscribed,
      });
    } else {
      return this.deviceService.subscribe({
        deviceId,
        subscribed,
      });
    }
  }
}
