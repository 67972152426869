import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  APP_INITIALIZER,
  ErrorHandler,
  Injectable,
  NgModule,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { Drivers } from '@ionic/storage';
import { IonicStorageModule } from '@ionic/storage-angular';
import * as Sentry from '@sentry/browser';
import { CacheModule } from 'ionic-cache';
import { DateFnsModule } from 'ngx-date-fns';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NotFoundRoutingModule } from './not-found/not-found-routing.module';
import { AreaStore } from './services/area/area.store';
import { AuthInterceptor } from './services/auth-interceptor.service';
import { DeviceStore } from './services/device/device-store';
import { LoadingInterceptor } from './services/loading-interceptor.service';
import { StartupService } from './services/startup.service';
import { UserStore } from './services/user.store';

window['hhRelease'] = '3.3.4';

if (environment.production) {
  Sentry.init({
    dsn: 'https://b812ff056f2b44bfa61e9c1a73c2d8c6@sentry.io/1315570',
    environment: environment.env,
    release: window['hhRelease'],
    enabled: environment.production,
    debug: environment.env === 'staging',
    attachStacktrace: true,
  });
}

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  handleError(error: any): void {
    if (!environment.production) {
      console.error(error);
    }
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (!chunkFailedMessage.test(error.message)) {
      Sentry.captureException(error.originalError || error);
    } else {
      console.error(chunkFailedMessage);
    }
  }
}

export const initConfiguration = (startupService: StartupService) => () =>
  startupService.load();
@NgModule({
  declarations: [AppComponent],
  imports: [
    NotFoundRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    ServiceWorkerModule.register('OneSignalSDKWorker.js', {
      enabled: environment.production,
    }),
    IonicStorageModule.forRoot({
      name: '__cleaner_pwa',
      driverOrder: [Drivers.LocalStorage, Drivers.IndexedDB],
    }),
    CacheModule.forRoot(),
    DateFnsModule.forRoot(),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true,
    },
    UserStore,
    AreaStore,
    DeviceStore,
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    StartupService,
    {
      provide: APP_INITIALIZER,
      useFactory: initConfiguration,
      multi: true,
      deps: [StartupService],
    },
    { provide: ErrorHandler, useClass: SentryErrorHandler },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
