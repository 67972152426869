import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AreaResolver } from './resolvers/area.resolver';
import { CleanerResolver } from './resolvers/cleaner.resolver';
import { DeviceResolver } from './resolvers/device.resolver';
import { NotFoundComponent } from './not-found/not-found.component';

const routes: Routes = [
  {
    path: 'app',
    resolve: {
      cleaners: CleanerResolver,
      devices: DeviceResolver,
    },
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/home/home.module').then((m: any) => m.HomePageModule),
      },
      {
        path: 'history',
        pathMatch: 'full',
        loadChildren: () =>
          import('./pages/history/history.module').then(
            (m: any) => m.HistoryPageModule
          ),
      },
      {
        path: 'tasks',
        loadChildren: () =>
          import('./pages/tasks/tasks.module').then(
            (m: any) => m.TasksPageModule
          ),
        resolve: {
          areas: AreaResolver,
        },
      },
      {
        path: 'invoices',
        loadChildren: () =>
          import('./pages/invoices/invoices.module').then(
            (m: any) => m.InvoicesPageModule
          ),
      },
      {
        path: 'correspondence-history',
        loadChildren: () =>
          import(
            './pages/correspondence-history/correspondence-history.module'
          ).then((m: any) => m.CorrespondenceHistoryPageModule),
      },
      {
        path: 'cleaning-reports',
        loadChildren: () =>
          import('./pages/cleaning-reports/cleaning-reports.module').then(
            (m: any) => m.CleaningReportsPageModule
          ),
        resolve: {
          areas: AreaResolver,
        },
      },
      {
        path: 'settings',
        loadChildren: () =>
          import('./pages/settings/settings.module').then(
            (m: any) => m.SettingsPageModule
          ),
      },
      {
        path: 'messages',
        loadChildren: () =>
          import('./pages/messages/messages.module').then(
            (m: any) => m.MessagesPageModule
          ),
      },
    ],
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./public/auth/auth.module').then((m: any) => m.AuthPageModule),
  },

  { path: '', pathMatch: 'full', redirectTo: 'app' },
  { path: '404', component: NotFoundComponent },
  { path: '**', redirectTo: '404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  providers: [AreaResolver, CleanerResolver, DeviceResolver],
})
export class AppRoutingModule {}
