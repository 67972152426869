import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import BookingSource from 'src/app/models/BookingSource';
import { Cacheable } from 'ts-cacheable';
import { BaseService } from '../base.service';

@Injectable({
  providedIn: 'root',
})
export class BookingSourceService extends BaseService {
  constructor(protected http: HttpClient) {
    super(http);
  }

  @Cacheable({
    maxAge: 1000 * 60 * 60 * 24,
  })
  fetchAll(): Observable<BookingSource[]> {
    return this.callGet('/booking-sources').pipe(
      map((response: any) => {
        if (response && Array.isArray(response.data)) {
          return response.data.map(BookingSource.parseFromData);
        }
        return [];
      }),
      catchError((response: HttpErrorResponse) =>
        this.getResponseMessage(response, 'Unable to fetch booking sources')
      )
    );
  }
}
