export class Device {
  public deviceId: string;
  public cleanerId: string;
  public subscribed: boolean;

  constructor(data: any) {
    this.deviceId = data.deviceId;
    this.cleanerId = data.cleanerId;
    this.subscribed = data.subscribed;
  }

  static parseFromData(data: any) {
    return new Device({
      deviceId: data.device_id,
      cleanerId: data.cleaner_id,
      subscribed: true,
    });
  }
}
