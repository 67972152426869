import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Cleaner } from '../models/Cleaner';
import { BaseStore } from './base.store';
import { AuthenticationService } from './authentication.service';
import { mergeMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UserStore extends BaseStore {
  private readonly _user = new BehaviorSubject<Cleaner>(null);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  readonly user$ = this._user.asObservable();

  constructor(protected authenticationService: AuthenticationService) {
    super();
    this.authenticationService = authenticationService;
  }

  get user(): Cleaner {
    return this._user.getValue();
  }

  set user(val: Cleaner) {
    this._user.next(val);
  }

  async init(): Promise<void> {
    if (this.user) {
      return;
    }

    const user = await this.authenticationService.getCachedUser();
    if (user) {
      this.user = Cleaner.parseFromData(user);
    }
  }

  login(data: { [key: string]: any }): Observable<Cleaner> {
    return this.authenticationService.login(data).pipe(
      mergeMap((user: Cleaner) => {
        this.user = user;
        return of(user);
      })
    );
  }

  async logout(): Promise<void> {
    await this.authenticationService.logout();
    this.user = null;
  }

  isAuthenticated(): boolean {
    return this.user !== null;
  }
}
