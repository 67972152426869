import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
} from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { LoadingController } from '@ionic/angular';
import { mergeMap, tap, delay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LoadingInterceptor implements HttpInterceptor {
  // eslint-disable-next-line no-undef
  protected loading: HTMLIonLoadingElement;
  protected totalRequests = 0;

  constructor(protected loadingController: LoadingController) {
    this.loadingController = loadingController;
  }

  async getLoader(): Promise<void> {
    if (this.totalRequests === 1) {
      this.loading = await this.loadingController.create({
        message: 'Loading...',
      });
      this.loading.present();
    }
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.headers.has('Skip-Loader')) {
      const headers = req.headers.delete('Skip-Loader');
      const modified = req.clone({ headers });
      return next.handle(modified);
    } else {
      this.totalRequests++;
      return from(this.getLoader()).pipe(
        mergeMap(() =>
          next.handle(req).pipe(
            delay(300),
            tap({
              next: (event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                  this.onEnd();
                }
              },
              error: (error: any) => {
                console.error(error);
                this.onEnd();
              },
            })
          )
        )
      );
    }
  }

  protected onEnd(): void {
    this.totalRequests--;
    if (this.totalRequests === 0) {
      this.loading.dismiss();
    }
  }
}
