import { animate, style, transition, trigger } from '@angular/animations';
import { ApplicationRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { AlertController, NavController, Platform } from '@ionic/angular';
import * as Sentry from '@sentry/browser';
import { Scope } from '@sentry/types';
import { get } from 'lodash-es';
import {
  concat,
  filter,
  fromEvent,
  interval,
  lastValueFrom,
  merge,
  of,
  Subscription,
} from 'rxjs';
import { first, map } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { Cleaner } from './models/Cleaner';
import { AreaStore } from './services/area/area.store';
import { BookingSourcesStore } from './services/booking-sources/booking-sources.store';
import { CleanerStore } from './services/cleaner/cleaner.store';
import { DeviceStore } from './services/device/device-store';
import { UserStore } from './services/user.store';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['./app.scss'],
  animations: [
    trigger('buttonState', [
      transition(':enter', [
        style({ transform: 'translateY(100%)' }),
        animate('300ms ease-in', style({ transform: 'translateY(0%)' })),
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({ transform: 'translateY(100%)' })),
      ]),
    ]),
  ],
})
export class AppComponent implements OnDestroy, OnInit {
  public isOnline = false;
  public wasOffline = false;

  public appPages = [
    {
      title: 'Home',
      url: '/',
      icon: 'home',
      protected: false,
    },
    {
      title: 'History',
      url: '/app/history',
      icon: 'checkmark',
      protected: false,
    },
    {
      title: 'Tasks',
      url: '/app/tasks',
      icon: 'list',
      protected: false,
    },
    {
      title: 'Invoices',
      url: '/app/invoices',
      icon: 'document-outline',
      protected: true,
    },
    {
      title: 'Correspondence History',
      url: '/app/correspondence-history',
      icon: 'mail',
      protected: false,
    },
    {
      title: 'Cleaning Reports',
      url: '/app/cleaning-reports',
      icon: 'clipboard',
      protected: false,
    },
    {
      title: 'Send Message',
      url: '/app/messages',
      icon: 'mail',
      protected: false,
    },
    {
      title: 'Settings',
      url: '/app/settings',
      icon: 'settings',
      protected: false,
    },
  ];

  public showMenu = false;
  public ready = false;

  public version: string = window['hhRelease'];
  public oneSignalInitialized = false;

  protected update$: Subscription;
  protected updateCheck$: Subscription;

  constructor(
    protected platform: Platform,
    protected userStore: UserStore,
    protected router: Router,
    protected nav: NavController,
    protected alertController: AlertController,
    protected swUpdate: SwUpdate,
    protected appRef: ApplicationRef,
    protected deviceStore: DeviceStore,
    protected areaStore: AreaStore,
    protected cleanerStore: CleanerStore,
    protected bookingSourceStore: BookingSourcesStore
  ) {
    this.platform = platform;
    this.userStore = userStore;
    this.nav = nav;
    this.cleanerStore = cleanerStore;
    this.router = router;
    this.alertController = alertController;
    this.swUpdate = swUpdate;
    this.appRef = appRef;
    this.deviceStore = deviceStore;
    this.areaStore = areaStore;
    this.bookingSourceStore = bookingSourceStore;
  }

  trackByPage(page: any): string {
    return page.url;
  }

  ngOnInit() {
    this.handleOffline();
    this.initializeUser();

    if (environment.production) {
      this.setupUpdateCheck();
      this.setupUpdate();
    }
  }

  handleOffline(): void {
    merge(
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true)),
      of(navigator.onLine)
    ).subscribe((isOnline: boolean) => {
      this.isOnline = isOnline;
      if (isOnline && this.wasOffline) {
        merge(
          this.areaStore.loadDataIfEmpty(),
          this.cleanerStore.loadDataIfEmpty(),
          this.deviceStore.loadDataIfEmpty(),
          this.bookingSourceStore.loadDataIfEmpty()
        ).subscribe(() => {
          this.registerOneSignal();
        });
      } else {
        this.wasOffline = true;
      }
    });
  }

  initializeUser(): void {
    this.userStore.user$.subscribe((user: Cleaner) => {
      if (user) {
        this.showMenu = true;
        this.registerOneSignal();
        Sentry.configureScope((scope: Scope) => {
          scope.setExtras(user as Record<string, any>);
          scope.setTag('company_name', get(user, 'account.company_name', null));
          scope.setTag('reference', get(user, 'account.reference', null));
          scope.setUser({
            id: get(user, 'id', null),
            email: get(user, 'email', null),
            username: get(user, 'full_name', null),
          });
        });
      } else if (!user) {
        this.showMenu = false;
      }
      this.ready = true;
    });
  }

  registerOneSignal(): void {
    if (environment.production && !this.oneSignalInitialized) {
      const oneSignal = window['OneSignal'] || [];
      oneSignal.push(() => {
        this.oneSignalInitialized = true;
        window['OneSignal'].init({
          appId: environment.ONE_SIGNAL_KEY,
          safariWebId: environment.ONE_SIGNAL_SAFARI_KEY,
          notifyButton: {
            enable: true,
          },
          autoResubscribe: true,
          allowLocalhostAsSecureOrigin: true,
          welcomeNotification: {
            title: 'Welcome',
            message: 'Thanks for subscribing!',
          },
          promptOptions: {
            /* actionMessage limited to 90 characters */
            actionMessage: `We'd like to show you notifications for the latest tasks.`,
            /* acceptButtonText limited to 15 characters */
            acceptButtonText: 'ALLOW',
            /* cancelButtonText limited to 15 characters */
            cancelButtonText: 'NO THANKS',
          },
        });
      });
      oneSignal.push(() => {
        window['OneSignal'].on(
          'subscriptionChange',
          (isSubscribed: boolean) => {
            window['OneSignal']
              .getUserId()
              .then(async (deviceId: string) => {
                const subscription$ = this.deviceStore.manageSubscription(
                  deviceId,
                  isSubscribed
                );
                await lastValueFrom(subscription$);
              })
              .catch(console.error);
          }
        );

        window['OneSignal'].on(
          'notificationDisplay',
          (event: Record<string, unknown>) => {
            console.warn('OneSignal notification displayed:', event);
          }
        );
        window['OneSignal'].on(
          'notificationDismiss',
          (event: Record<string, unknown>) => {
            console.warn('OneSignal notification dismissed:', event);
          }
        );

        const setNotification = () => {
          oneSignal.push([
            'addListenerForNotificationOpened',
            (notificationData: any) => {
              console.log('Received NotificationOpened:', notificationData);
              setNotification();
            },
          ]);
        };
        setNotification();
      });
    }
  }

  async logout(): Promise<any> {
    await this.userStore.logout();
    this.nav.setDirection('root');
    this.router.navigate(['auth/login']);
  }

  async refresh(): Promise<void> {
    const confirm = await this.alertController.create({
      header: 'Update',
      message: 'Do you want to reload the app?',
      buttons: [
        {
          text: 'Cancel',
          handler: () => null,
        },
        {
          text: 'Reload',
          handler: () => {
            this.swUpdate
              .activateUpdate()
              .then(() => document.location.reload());
          },
        },
      ],
    });
    await confirm.present();
  }

  setupUpdateCheck(): void {
    if (this.updateCheck$) {
      return;
    }
    const appIsStable$ = this.appRef.isStable.pipe(
      first((isStable: boolean) => isStable === true)
    );
    const everySixHours$ = interval(6 * 60 * 60 * 1000);
    const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);

    if (this.swUpdate.isEnabled) {
      this.updateCheck$ = everySixHoursOnceAppIsStable$.subscribe(() => {
        this.swUpdate.checkForUpdate();
      });
    }
  }

  setupUpdate() {
    if (this.update$) {
      return;
    }
    this.update$ = this.swUpdate.versionUpdates
      .pipe(
        filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
        map(evt => ({
          type: 'UPDATE_AVAILABLE',
          current: evt.currentVersion,
          available: evt.latestVersion,
        }))
      )
      .subscribe((evt: any) => {
        console.log(evt);
        this.alertController
          .create({
            header: 'Update',
            message:
              'New Update available! Reload the webapp to see the latest juicy changes.',
            buttons: [
              {
                text: 'Cancel',
                handler: () => {},
              },
              {
                text: 'Reload',
                handler: () => {
                  this.swUpdate
                    .activateUpdate()
                    .then(() => window.location.reload());
                },
              },
            ],
          })
          .then((confirm: HTMLIonAlertElement) => confirm.present())
          .catch(console.error);
      });
  }

  ngOnDestroy(): void {
    if (environment.production) {
      this.update$.unsubscribe();
      this.updateCheck$.unsubscribe();
    }
  }
}
