import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import * as Sentry from '@sentry/browser';
import { from, Observable, of, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { globalCacheBusterNotifier } from 'ts-cacheable';
import { environment } from '../../environments/environment';
import { Cleaner } from '../models/Cleaner';
import { StorageService } from './storage.service';

const AUTH_USER = 'auth-user-cleaner-pwa';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(
    protected storage: StorageService,
    protected platform: Platform,
    protected http: HttpClient
  ) {
    this.storage = storage;
    this.platform = platform;
    this.http = http;
  }

  async getCachedUser() {
    const user = await this.storage.get(AUTH_USER);
    return user;
  }

  login(loginData: any): Observable<Cleaner> {
    if (!navigator.onLine) {
      return throwError(
        () => new Error('You are offline. Please connect to the internet.')
      );
    }
    const headers = new HttpHeaders({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Content-Type': 'application/json',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Skip-Auth': 'true',
    });
    return this.http
      .post(`${environment.API_URL}/login`, loginData, { headers })
      .pipe(
        mergeMap((response: any) => {
          if (response.success && !response.data) {
            return throwError(() => new Error(response.message as string));
          }
          const user = Cleaner.parseFromData(response.data) || null;
          return from(this.storage.set(AUTH_USER, response.data)).pipe(
            mergeMap(() => of(user))
          );
        }),
        catchError((error: HttpErrorResponse) => {
          if (error && error.message && error.message === 'confirm-pincode') {
            return throwError(() => error.message);
          }
          if (error && error.status !== 401 && error.status !== 504) {
            Sentry.captureEvent({
              message: error.message,
              extra: error as Record<string, any>,
            });
          }
          return throwError(
            () =>
              new Error(
                'Cannot login. Make sure all login details are correct.'
              )
          );
        })
      );
  }

  async logout(): Promise<void> {
    globalCacheBusterNotifier.next();
    await this.storage.remove(AUTH_USER);
    // fix for the left pane to be hidden after logout
    await new Promise((resolve: CallableFunction) => setTimeout(resolve, 200));
  }
}
