import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import BookingSource from 'src/app/models/BookingSource';
import { BaseStore } from '../base.store';
import { BookingSourceService } from './booking-sources.service';

@Injectable({ providedIn: 'root' })
export class BookingSourcesStore extends BaseStore {
  private readonly _bookingSources = new BehaviorSubject<BookingSource[]>([]);

  // eslint-disable-next-line @typescript-eslint/member-ordering
  readonly bookingSources$ = this._bookingSources.asObservable();

  constructor(protected bookingSourceService: BookingSourceService) {
    super();
  }

  get bookingSources(): BookingSource[] {
    return this._bookingSources.getValue();
  }

  set bookingSources(val: BookingSource[]) {
    this._bookingSources.next(val);
  }

  getBookingSourceById(bookingSourceId: number): BookingSource {
    return (
      this.bookingSources.find(
        (bookingSource: BookingSource) => bookingSource.id === bookingSourceId
      ) || null
    );
  }

  loadDataIfEmpty(): Observable<BookingSource[]> {
    if (this.bookingSources.length === 0) {
      return this.loadData().pipe(catchError(() => []));
    }
    return of([]);
  }

  loadData(): Observable<BookingSource[]> {
    this.loading = true;
    this.error = null;

    return this.bookingSourceService.fetchAll().pipe(
      finalize(() => (this.loading = false)),
      tap((bookingSources: BookingSource[]) => {
        this.bookingSources = bookingSources;
      })
    );
  }
}
