import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Cleaner } from 'src/app/models/Cleaner';
import { Cacheable } from 'ts-cacheable';
import { BaseService } from '../base.service';
import { UserStore } from '../user.store';

@Injectable({
  providedIn: 'root',
})
export class CleanerService extends BaseService {
  constructor(protected http: HttpClient, protected userStore: UserStore) {
    super(http);
  }

  @Cacheable({
    maxAge: 1000 * 60 * 60,
  })
  fetchAll(): Observable<Cleaner[]> {
    return this.callGet(`/cleaners`).pipe(
      map((response: any) => {
        if (response && Array.isArray(response.data)) {
          return response.data.map(Cleaner.parseFromData);
        }
        return [];
      }),
      catchError((response: HttpErrorResponse) =>
        this.getResponseMessage(response, 'Unable to fetch cleaners')
      )
    );
  }
}
