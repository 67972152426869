import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { EMPTY, from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Injectable()
export class BaseResolver {
  constructor(
    protected router: Router,
    protected toastController: ToastController
  ) {
    this.router = router;
    this.toastController = toastController;
  }

  handleResolverError(error: string) {
    return from(
      this.toastController.create({
        message: error || 'Unable to fetch data',
        duration: 3000,
        position: 'top',
      })
    ).pipe(
      mergeMap((toast: any) =>
        from(toast.present()).pipe(mergeMap(() => EMPTY))
      )
    );
  }
}
