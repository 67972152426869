import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Cleaner } from '../models/Cleaner';
import { BaseResolver } from '../services/base.resolver';
import { CleanerStore } from '../services/cleaner/cleaner.store';

@Injectable()
export class CleanerResolver
  extends BaseResolver
  implements Resolve<Cleaner[]>
{
  constructor(
    protected cleanerStore: CleanerStore,
    protected router: Router,
    protected toastController: ToastController
  ) {
    super(router, toastController);
  }

  resolve(): Observable<Cleaner[]> {
    return this.cleanerStore.loadData().pipe(
      catchError((e: string) => {
        this.handleResolverError(e);
        return of([]);
      })
    );
  }
}
