import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Device } from '../models/Device';
import { BaseResolver } from '../services/base.resolver';
import { DeviceStore } from '../services/device/device-store';

@Injectable()
export class DeviceResolver extends BaseResolver implements Resolve<Device[]> {
  constructor(
    protected deviceStore: DeviceStore,
    protected router: Router,
    protected toastController: ToastController
  ) {
    super(router, toastController);
  }

  resolve(): Observable<Device[]> {
    return this.deviceStore.loadData().pipe(
      catchError((e: string) => {
        this.handleResolverError(e);
        return of([]);
      })
    );
  }
}
