<ion-app>
  <ion-split-pane *ngIf="ready" [disabled]="!showMenu" contentId="main-content">
    <ion-menu contentId="main-content">
      <ion-header>
        <ion-toolbar color="primary">
          <ion-title>HomHero Cleaner</ion-title>
        </ion-toolbar>
        <ion-toolbar
          color="primary"
          *ngIf="userStore.user"
          style="padding-left: 15px !important; font-size: 0.8rem"
        >
          <div>
            {{ userStore.user.name }}
            {{ userStore.user.manager() ? '(Manager)' : '' }}
          </div>
          <div>{{ userStore.user.email }}</div>
        </ion-toolbar>
      </ion-header>
      <ion-content *ngIf="showMenu">
        <ion-list class="app-menu">
          <ion-menu-toggle
            auto-hide="false"
            *ngFor="let p of appPages; trackBy: trackByPage"
          >
            <ion-item
              [routerDirection]="'root'"
              [routerLink]="[p.url]"
              *ngIf="
                !p.protected ||
                (p.protected && userStore.user && userStore.user.manager())
              "
            >
              <ion-icon
                slot="start"
                [name]="p.icon"
                class="menu-icon"
              ></ion-icon>
              <ion-label class="menu-text"> {{ p.title }} </ion-label>
            </ion-item>
          </ion-menu-toggle>
          <ion-menu-toggle auto-hide="false">
            <ion-item (click)="refresh()">
              <ion-icon
                slot="start"
                name="refresh"
                class="menu-icon"
              ></ion-icon>
              <ion-label class="menu-text"> Get latest updates </ion-label>
            </ion-item>
          </ion-menu-toggle>
          <ion-menu-toggle auto-hide="false">
            <ion-item (click)="logout()">
              <ion-icon
                slot="start"
                name="log-out"
                class="menu-icon"
              ></ion-icon>
              <ion-label class="menu-text"> Logout </ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
      </ion-content>
      <ion-footer>
        <ion-toolbar>
          <div class="ion-padding">
            Powered by
            <a
              href="https://homhero.com.au"
              title="HOMHERO"
              target="blank"
              class="menu-text"
              >HOMHERO</a
            >
            v{{ version }}
          </div>
        </ion-toolbar>
      </ion-footer>
    </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
  <div [@buttonState] *ngIf="!isOnline" class="app-offline-footer">
    You are offline!
  </div>
</ion-app>
